@import "../assets/css/fontello.css";
@import "../../node_modules/bulma/sass/utilities/initial-variables";
// bulma customization
$link: #607d8b;
$primary: #455a64;

$black: #212121;
$white: #fafafa;
$table-row-hover-background-color: darken($white, 10%);

// responsive breakpoints
$desktop: 960px + (2 * $gap);
$widescreen: 960px + (2 * $gap);
$fullhd: 960px + (2 * $gap);

@import "../../node_modules/bulma/sass/utilities/_all";
@import "../../node_modules/bulma/sass/base/_all";
@import "../../node_modules/bulma/sass/elements/container";
@import "../../node_modules/bulma/sass/elements/content";
@import "../../node_modules/bulma/sass/elements/table";
@import "../../node_modules/bulma/sass/elements/button";
@import "../../node_modules/bulma/sass/elements/tag";
@import "../../node_modules/bulma/sass/elements/title";
@import "../../node_modules/bulma/sass/layout/section";
@import "../../node_modules/bulma/sass/layout/footer";
@import "../../node_modules/bulma/sass/components/tabs";
@import "../../node_modules/bulma/sass/components/navbar";
@import "../../node_modules/bulma/sass/components/card";
@import "../../node_modules/bulma/sass/form/shared";
@import "../../node_modules/bulma/sass/form/input-textarea";
@import "../../node_modules/bulma/sass/form/file";
@import "../../node_modules/bulma/sass/form/tools";
@import "../../node_modules/bulma/sass/helpers/float.sass";
@import "../../node_modules/bulma/sass/helpers/typography.sass";
@import "../../node_modules/bulma/sass/helpers/color.sass";
@import "../../node_modules/bulma/sass/helpers/spacing.sass";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: whitesmoke;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;

  > div {
    background: #fafafa;
  }
}

ul.link-list {
  list-style-type: none;
  padding-left: 0;

  li {
    line-height: 3rem;
    border-bottom: 1px solid silver;
  }
}

// hacky styling fix for fixed header
.section {
  padding-top: 4rem;
}

.title {
  font-size: 1.25rem;
}

.center {
  text-align: center !important;
}

.loading {
  width: 60px;
  margin: 0 auto;
}

.result {
  width: 3rem;
  vertical-align: middle !important;
  font-weight: bold;
}

table td {
  vertical-align: middle !important;
}

td.thin {
  width: 1rem;
  padding: 0.5em 0;
}

td.thin > i {
  color: lighten($primary, 40%);
}

@media all and (max-width: 400px) {
  .optional {
    display: none !important;
  }
}

@media all and (max-width: 470px) {
  .optional-2 {
    display: none !important;
  }
}

@media all and (max-width: 540px) {
  .optional-3 {
    display: none !important;
  }
}

@media all and (max-width: 610px) {
  .optional-4 {
    display: none !important;
  }
}

tr.no-hover {
  background-color: transparent !important;
}

td.logo-col {
  padding: 7px 0 0;
  height: 54px;
  text-align: center !important;
}

.logo {
  max-width: 40px;
  max-height: 40px;
  border-radius: 5px;
}

.logo-lg {
  max-width: 80px;
  max-height: 80px;
}

.logo-row {
  display: flex;
  align-items: center;
}
